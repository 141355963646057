import { _axios } from '@/plugins/axios'

export const apiMpesaUploadFile = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/accounting/mpesa/uploadFile`,
		method: method,
		data: dataJson
	})
}
export const apiMpesaPage = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/accounting/mpesa/page`,
		method: method,
		data: dataJson
	})
}
export const apiMpesaFilePage = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/accounting/mpesa/filePage`,
		method: method,
		data: dataJson
	})
}
export const apiMpesaIgnore = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/accounting/mpesa/ignore/${dataJson}`,
		method: method
	})
}
export const apiMpesaUploadRepay = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/accounting/mpesa/upload/repay`,
		method: method,
		data: dataJson
	})
}
export const apiMpesaUploadOverpaid = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/accounting/mpesa/upload/overpaid`,
		method: method,
		data: dataJson
	})
}
export const apiDtbUploadFile = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/accounting/dtb/uploadFile`,
		method: method,
		data: dataJson
	})
}
export const apiDtbPage = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/accounting/dtb/page`,
		method: method,
		data: dataJson
	})
}

export const apiDtbFilePage = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/accounting/dtb/filePage`,
		method: method,
		data: dataJson
	})
}
export const apiDtbIgnore = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/accounting/dtb/ignore/${dataJson}`,
		method: method
	})
}
export const apiDtbUploadRepay = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/accounting/dtb/upload/repay`,
		method: method,
		data: dataJson
	})
}
export const apiDtbUploadOverpaid = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/accounting/dtb/upload/overpaid`,
		method: method,
		data: dataJson
	})
}
